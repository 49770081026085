<template>
  <v-card>
    <v-card-title>
      <v-icon left>{{icons.mdiAccountMultiplePlus}}</v-icon>
      <span class="primary--text-kh">{{$t('caption.positionCreation')}}</span>
      <v-spacer></v-spacer>
      <v-btn class="primary" small @click.stop="dialog = true">
        <v-icon left>{{icons.mdiPlusThick}}</v-icon> New Position
      </v-btn>
    </v-card-title>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          <v-icon left>{{icons.mdiAccount}}</v-icon> {{ dialogTitle }}
        </v-card-title>
        <br />
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field :label="$t('text.positionNameKh')" :append-icon="icons.mdiAccount" dense v-model="position.nameKh" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field :label="$t('text.positionNameKh')" :append-icon="icons.mdiAccount" v-model="position.nameEn" dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select :items="departmentList" item-value="id" :label="$t('select.department')" v-model="position.departmentId" single-line dense>
                  <template v-slot:selection="data">
                    <td v-if="$t('lang') == 'KH'">
                      <v-chip color="primary">{{data.item.nameKh}}</v-chip>
                    </td>
                    <td v-else>
                      <v-chip color="primary">{{data.item.nameEn}}</v-chip>
                    </td>
                  </template>
                  <template v-slot:item="data">
                    <td v-if="$t('lang') == 'KH'">
                      {{data.item.nameKh}}
                    </td>
                    <td v-else>
                      {{data.item.nameEn}}
                    </td>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="save">
            <v-icon dark left>{{icons.mdiContentSave}}</v-icon> {{$t('button.save')}}
          </v-btn>
          <v-btn class="secondary" @click="dialog = false">
            <v-icon left>{{icons.mdiCancel}}</v-icon> Cancel
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-select :items="departmentList" item-value="id" :label="$t('select.department')" outlined dense rounded @change="changeDept($event)">
            <template v-slot:selection="data">
              <span v-if="$t('lang') == 'KH'">
                {{data.item.nameKh}}
              </span>
              <span v-else>
                {{data.item.nameEn}}
              </span>
            </template>
            <template v-slot:item="data">
              <span v-if="$t('lang') == 'KH'">
                {{data.item.nameKh}}
              </span>
              <span v-else>
                {{data.item.nameEn}}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field :prepend-inner-icon="icons.mdiMagnify" rounded single-line outlined dense v-model="search" label="ស្វែករកតាមឈ្មោះ"></v-text-field>
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="positionList" :search="search">

        <template #[`item.departmentId`]="{item}">
          <span v-if="$t('lang') === 'KH' && item.departmentId && getDepartmentNameById(item.departmentId)[0]">
            {{ getDepartmentNameById(item.departmentId)[0].nameKh }}
          </span>
          <span v-else-if="item.departmentId && getDepartmentNameById(item.departmentId)[0]">
            {{ getDepartmentNameById(item.departmentId)[0].nameEn }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-2" @click="editItem(item)">
            {{icons.mdiPencil}}
          </v-icon>
          <v-icon medium @click="deleteItem(item)">
            {{icons.mdiDelete}}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiContentSave,
  mdiCancel,
  mdiEye,
  mdiMagnify,
  mdiPlusThick
} from '@mdi/js'
import systemService from '@/services/service.system'

export default {
  name: 'position-list',
  data() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiContentSave,
        mdiCancel,
        mdiEye,
        mdiMagnify,
        mdiPlusThick
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name (KH)', value: 'nameKh' },
        { text: 'Name (EN) ', value: 'nameEn' },
        { text: 'Department or Entity', value: 'departmentId' },
        { text: 'ACTION', value: 'actions', sortable: false }
      ],
      positionList: [],
      departmentList: [],
      position: {},
      dialog: false,
      dialogDelete: false,
      search: '',
      resultMessage: '',
      editedIndex: -1
    }
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1
        ? this.$t('caption.addPosition')
        : this.$t('caption.editPosition')
    },
    selectedItems: {
      get() {
        return this.value
      },
      set(item) {
        this.chosenItems.push(item)
        this.$emit('input', item)
      }
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  mounted() {
    systemService.getPositionAll().then(response => {
      this.positionList = response.data
    })
    systemService.getDepartmentAll().then(response => {
      this.departmentList = response.data
    })
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.positionList.indexOf(item)
      this.position = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.$confirm({
        message: 'Do you want to delete ' + item.nameKh + '?',
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            systemService.deletePositionById(item.id).then(
              () => {
                const index = this.positionList.indexOf(item)
                this.positionList.splice(index, 1)
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'success',
                  text: 'Position has been deleted'
                })
              },
              error => {
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'error',
                  text: error.response.data
                })
              }
            )
          }
        }
      })
    },
    save() {
      if (!this.$refs.form.validate()) return
      systemService.insertPosition(this.position).then(
        response => {
          if (response.data) {
            if (this.editedIndex == -1) {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.addedPosition')
              })

              this.positionList.push(response.data)
            } else {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.updatedPosition')
              })
              Object.assign(this.positionList[this.editedIndex], response.data)
            }
            this.dialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.position = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.position = { roles: [], isActive: true }
      })
    },
    getDepartmentNameById(id) {
      // alert(id);
      return this.departmentList.filter(function(elem) {
        //  console.log(elem);
        return elem.id === id
        //return elem;
      })
    },
    changeDept: function(deptId) {
      systemService.getPositionByDepartmentId(deptId).then(response => {
        this.positionList = response.data
      })
    }
  }
}
</script>
